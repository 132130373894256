import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AddChildProfile from './ChildProfileAdd';
import EditStudyPlan from './StudyPlanEdit';
import AddStudyPlan from './StudyPlanAdd';
import lfApi from '../api/lfApi';
import { getProfilePicture } from '../api/profile';
import RecentActivities from './RecentActivities';
import StudyPlanProgress from './StudyPlanProgress';
import ToggleButton from './ToggleButton';

async function getStudyPlans(username, isArchived) {
  try {
    const response = await lfApi.get('/lesson_plan/', {
      params: {
        child_username: username,
        is_deleted: isArchived,
      }
    });
    if (response.status === 200) {
      return response.data;
    } 
    else {
      console.error('Failed to fetch study plans');
    }
  }
  catch (error) {
    console.error('Error fetching study plans: ', error);
  }
  return null;
}

async function postStudyPlan(username, studyPlan) {
  try {
    const response = await lfApi.post('/lesson_plan/', {
      child_username: username,
      study_plan: studyPlan,
    });
    if (response.status === 200) {
      return true;
    } 
    else {
      console.error('Failed to upsert study plan: ', studyPlan);
    }
  }
  catch (error) {
    console.error('Error upserting study plan:', error);
  }
  return false;
}

async function postStudyPlanDisplayOrder(username, studyPlans) {
  try {
    const response = await lfApi.post('/lesson_plan/display_orders', {
      child_username: username,
      study_plan_ids: studyPlans.map(plan => plan.id),
    });
    if (response.status === 200) {
      return true;
    } 
    else {
      console.error('Failed to upsert study plan display order: ', studyPlans);
    }
  }
  catch (error) {
    console.error('Error upserting study plan display order:', error);
  }
  return false;
}

async function delStudyPlan(username, studyPlan) {
  try {
    const response = await lfApi.delete('/lesson_plan/', {
      params: {
        child_username: username,
        study_plan_id: studyPlan.id,
      }
    });
    if (response.status === 200) {
      return true;
    } 
    else {
      console.error('Failed to delete study plan: ', studyPlan);
    }
  }
  catch (error) {
    console.error('Error deleting study plan:', error);
  }
  return false;
}

async function postPictureUrl(childProfile) {
  try {
    const response = await lfApi.post(
        'user/child/picture_url', 
        { 
          username: childProfile.username,
          picture_url: childProfile.picture_url,
        }
    );
    if (response.status === 200) {
      return true;
    }
    else {
      console.error('Failed to update picture url');
    }
  }
  catch (error) {
    console.error('Error updating picture url:', error);
  }
  return null;
}

async function postChildProfile(childProfile) {
  try {
    const response = await lfApi.post('/user/child', childProfile);
    if (response.status === 200) {
      return response.data;
    } 
    else {
      console.error('Failed to add child profile');
    }
  }
  catch (error) {
    console.error('Error adding child profile:', error);
  }

  return null;
}

const ChildProfile = ({ childProfiles, fetchChildProfiles, subscriptionAction }) => {
  const { username } = useParams();

  const [studyPlans, setStudyPlans] = useState([]);
  const [completedVideoIds, setCompletedVideoIds] = useState({});

  // Current study plan being edited
  const [currentStudyPlan, setCurrentStudyPlan] = useState(null);

  const [isEditPicture, setIsEditPicture] = useState(false);

  const [isArchived, setIsArchived] = useState(false);

  const emojis = [
    '🎾', '⚽️', '🏀', '⚾️', '🏐', '🏓', '⛳️', '🛹',
    '🐶', '🐱', '🐭', '🦊', '🐻', '🐻‍❄️', '🐯', '🦁',
    '🍎', '🍊', '🍓', '🫐', '🥭', '🍌', '🍉', '🥝',
    '🚗', '🚕', '🚙', '🚌', '🚎', '🏎', '🚓', '🚑',
    '🎸', '🎹', '🎷', '🎺', '🎻', '🪕', '🥁', '🪘',
  ];

  function getUsername() {
    return username || 
      (childProfiles && childProfiles.length > 0 ? childProfiles[0].username : '');
  }

  function getChildProfile() {
    const childProfile = username
      ? childProfiles.find(child => child.username === username)
      : childProfiles[0];
    return childProfile || {};
  }

  function hasSubscriptionAction() {
    return subscriptionAction.action !== 'none';
  }

  const navigate = useNavigate();

  const navigateToParent = () => {
    navigate('');
  };

  const navigateToProgress = () => {
    navigate('progress');
  };

  const navigateToEdit = (username) => {
    navigate('edit');
  };

  const navigateToAddStudyPlan = () => {
    navigate('add_study_plan');
  };

  const navigateToEditStudyPlan = () => {
    navigate('edit_study_plan');
  };

  const upsertStudyPlan = async (studyPlan) => {
    const response = await postStudyPlan(getUsername(), studyPlan);
    if (response) {
      await fetchStudyPlans();
    }
  };

  const updateStudyPlanDisplayOrder = async (studyPlans) => {
    const response = await postStudyPlanDisplayOrder(getUsername(), studyPlans);
    if (response) {
      await fetchStudyPlans();
    }
  };

  const deleteStudyPlan = async (studyPlan) => {
    const response = await delStudyPlan(getUsername(), studyPlan);
    if (response) {
      await fetchStudyPlans();
    }
  };

  const fetchStudyPlans = async () => {
      const response = await getStudyPlans(getUsername(), isArchived);
      if (response) {
        setStudyPlans(response.study_plans);
        setCompletedVideoIds(response.completed_video_ids);
      } 
  };

  const editChildProfile = async (childProfile) => {
    const response = await postChildProfile(childProfile);
    if (response) {
      await fetchChildProfiles();
    }
  };

  const onPictureUrl = () => {
    setIsEditPicture(true);
  };

  const onEmoji = async (emoji) => {
    const newChildProfile = { ...getChildProfile(), picture_url: `emoji://${emoji}` };
    const response = await postPictureUrl(newChildProfile);
    if (response) {
      await fetchChildProfiles();
    }

    setIsEditPicture(false);
  };

  const onEditProfile = (username) => {
    navigateToEdit(username);
  };

  const onEditStudyPlan = (lessonPlan) => {
    setCurrentStudyPlan(lessonPlan);
    navigateToEditStudyPlan();
  };

  const onAddStudyPlan = () => {
    navigateToAddStudyPlan();
  };

  const onEditOk = async (childProfile) => {
    await editChildProfile(childProfile);
    navigateToParent();
  };

  const onUpsertStudyPlanOk = async (lessonPlan, doNavigate = true) => {
    await upsertStudyPlan(lessonPlan);
    
    if (doNavigate)
      navigateToParent();
  };

  const onDeleteStudyPlan = async (lessonPlan) => {
    await deleteStudyPlan(lessonPlan);
    navigateToParent();
  };
  
  const onDragEnd = (result) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;
  
    const items = Array.from(studyPlans);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
  
    setStudyPlans(items);
    updateStudyPlanDisplayOrder(items);
  };
  
  const onCancel = () => {
    navigateToParent();
  };

  useEffect(() => {
    fetchStudyPlans();
  }, [username, isArchived]); // The empty array means this effect runs once on mount

  return <div>
    <Routes>
      <Route path="" element={
        <div className="text-left mx-auto max-w-screen-lg">
          <div className="flex justify-center">
              <div className="border border-gray-300 bg-white rounded-2xl flex flex-row">
                  <div className="px-4 py-2 text-sm text-white bg-blue-500 rounded-l-2xl">
                      Profile
                  </div>
                  <div className="px-4 py-2 text-sm text-gray-600 cursor-pointer"
                    onClick={navigateToProgress}>
                      Progress
                  </div>
              </div>
          </div>

          <div className="mb-4 flex items-center relative">
            <div className="w-24 h-24 rounded-full bg-white border border-gray-300 flex items-center justify-center cursor-pointer"
                  onClick={onPictureUrl}>
              {
                getProfilePicture(getChildProfile(), emojis[0])
              }
            </div>
            {
              isEditPicture &&
              <div className="absolute top-6 left-6 right-6 bg-white border border-gray-500 rounded-lg p-4">
              {
                emojis.map((emoji, index) => (
                  <div key={index} className="inline-block cursor-pointer p-2 hover:bg-gray-200 rounded-lg" 
                        onClick={() => onEmoji(emoji)}>
                    {emoji}
                  </div>
                ))
              }
              </div>
            }
            <div className="text-center ml-2">{getChildProfile().first_name}</div>
          </div>

          <h1 className="text-xl font-semibold">Profile</h1>

          <table>
            <tbody>
              <tr>
                <td>Grade level: </td>
                <td>{getChildProfile().grade_level}</td>
              </tr>
              <tr>
                <td>Username: </td>
                <td>{getChildProfile().username}</td>
              </tr>
              <tr>
                <td>Password: </td>
                <td>********</td>
              </tr>
            </tbody>
          </table>

          <div className="my-2">
            <button className="bg-blue-500 text-white py-2 px-4 rounded cursor-pointer"
              onClick={onEditProfile}>
              Edit profile
            </button>
          </div>

          <h1 className="text-xl font-semibold mt-8">Study plans</h1>

          {
            studyPlans.length > 0 &&
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppableTable">
                {(provided, snapshot) => (
                <table className="border border-gray-200 mt-2">
                  <thead className="bg-gray-200">
                    <tr>
                      <th className="font-normal p-1">Subject</th>
                      <th className="font-normal p-1">Learning goals</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody {...provided.droppableProps} ref={provided.innerRef}>
                    {
                      studyPlans.map((studyPlan, i) => (
                        <Draggable key={studyPlan.id} draggableId={studyPlan.id} index={i}>
                          {(provided) => (
                          <tr 
                            {...provided.draggableProps} {...provided.dragHandleProps} 
                            ref={provided.innerRef}
                            style={{
                              ...provided.draggableProps.style,
                              tableLayout: 'fixed'
                            }}
                            className="border border-gray-200 bg-white">
                            <td className="p-1 pr-4">{studyPlan.emoji} {studyPlan.subject}</td>
                            <td className="p-1 pr-6">{studyPlan.goal}</td>
                            <td className="p-1">
                              <button className={`${hasSubscriptionAction() ? "bg-gray-400": "bg-blue-500"} text-white py-1 px-4 rounded mr-2`}
                                disabled={hasSubscriptionAction()}
                                onClick={() => onEditStudyPlan(studyPlan)}>
                                Edit
                              </button>
                              {
                                !isArchived && <button className="bg-blue-500 text-white py-1 px-4 rounded cursor-pointer mr-2"
                                  onClick={() => onDeleteStudyPlan(studyPlan)}>
                                  Archive
                                </button>
                              }
                            </td>
                          </tr>
                          )}
                        </Draggable>
                      ))
                    }
                    {provided.placeholder}
                  </tbody>
                </table>
                )}
              </Droppable>
            </DragDropContext>
          }
          
          <div className="mt-4 flex">
            <button className={`${isArchived || hasSubscriptionAction() ? "bg-gray-400": "bg-blue-500"} text-white py-2 px-4 rounded`}
              onClick={onAddStudyPlan}
              disabled={isArchived || hasSubscriptionAction()}>
              Add study plan
            </button>
            <div className="ml-4 flex items-center">
              <ToggleButton isToggled={isArchived} setIsToggled={setIsArchived} />
              <span className="ml-1 text-gray-500">Archived</span>
            </div>
          </div>
        </div>
      } />
      <Route path="progress" element={
        <div className="text-left">
          <div className="flex justify-center">
              <div className="border border-gray-300 bg-white rounded-2xl flex flex-row">
                  <div className="px-4 py-2 text-sm text-gray-600 cursor-pointer"
                      onClick={navigateToParent}>
                      Profile
                  </div>
                  <div className="px-4 py-2 text-sm text-white bg-blue-500 rounded-r-2xl">
                      Progress
                  </div>
              </div>
          </div>

          <h1 className="text-xl font-semibold mt-8 mb-2">{getChildProfile().first_name}'s Progress</h1>

          <StudyPlanProgress studyPlans={studyPlans} completedVideoIds={completedVideoIds} />
          
          <h1 className="text-xl font-semibold mt-8">Recent activities</h1>

          <RecentActivities username={username} />
        </div>
      } />
      <Route path="edit" element={
        <AddChildProfile
          childProfile={getChildProfile()} showConsent={false} 
          onOk={onEditOk} onCancel={onCancel} />
      } />
      <Route path="add_study_plan/*" element={
        <AddStudyPlan 
          childProfile={getChildProfile()} lessonPlan={null} 
          onOk={onUpsertStudyPlanOk} onCancel={onCancel} />
      } />
      <Route path="edit_study_plan" element={
        <EditStudyPlan 
          childProfile={getChildProfile()} lessonPlan={currentStudyPlan} isArchived={isArchived}
          onOk={onUpsertStudyPlanOk} onCancel={onCancel} />
      } />
    </Routes>
  </div>;
};

export default ChildProfile;
