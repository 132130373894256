    import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import lfApi from '../api/lfApi';
import LoadingButton from '../components/LoadingButton';

async function createProfile(userProfile) {
    try {
        const response = await lfApi.post('/user/profile', { ...userProfile, do_token_auth: true });
        if (response.status === 200) {
            return response.data;
        }
        else {
            console.log('Create profile failed');
        }
    }
    catch (error) {
        console.error('Create profile failed: ', error);
    }
    return null;
}

function SignupPage() {
    const [userProfile, setUserProfile] = useState({ 
        email: '',
        password: '',
        display_name: '',
    });
    const [isCreating, setIsCreating] = useState(false);
    const [message, setMessage] = useState('');

    const navigate = useNavigate();

    function hasEmailAndPassword() {
        return userProfile.email && userProfile.password;
    }

    async function handleCreate(event) {
        if (event)
            event.preventDefault();

        if (!hasEmailAndPassword() || isCreating) {
            return;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(userProfile.email)) {
            setMessage('Invalid email address');
            return;
        }

        setIsCreating(true);

        const profile = await createProfile(userProfile);
        if (profile === null) {
            setMessage('Something went wrong. Please try again later.');
        }
        else if (!profile.success) {
            setMessage('Email already in use. Please use a different email.');
            setUserProfile(prevState => ({ ...prevState, password: '', display_name: '' }));
        }
        else {
            navigate(`/?username=${encodeURIComponent(userProfile.email)}`);
        }

        setIsCreating(false);
    };

    function handleChange(e) {
        const { name, value } = e.target;
        setUserProfile(prevState => ({ ...prevState, [name]: value }));

        setMessage('');
    };

    return <div className="flex mx-auto max-w-screen-lg my-8">
        <div className="inline-flex flex-col items-center border border-gray-300 bg-white rounded-2xl mx-auto py-5 px-5 sm:py-10 sm:px-20">
            <div className="text-sm mb-1">Welcome to Learnforest AI</div>
            <h2 className="text-lg mb-6">Create Parent Account</h2>
            <form onSubmit={handleCreate} className="flex flex-col">
                <div className="grid grid-cols-[auto,1fr] gap-1 items-center text-left mb-3">
                    <div className="text-right mr-1">Email: </div>
                    <div>
                        <input
                            type="email"
                            className="border border-gray-300 p-1 my-1"
                            name="email"
                            value={userProfile.email}
                            onChange={handleChange} />
                    </div>
                    <div className="text-right mr-1">Password: </div>
                    <div>
                        <input
                            type="password"
                            className="border border-gray-300 p-1 my-1"
                            name="password"
                            value={userProfile.password}
                            onChange={handleChange} />
                    </div>
                    <div className="text-right mr-1">Display name: </div>
                    <div>
                        <input
                            type="text"
                            className="border border-gray-300 p-1 my-1"
                            name="display_name"
                            value={userProfile.display_name}
                            onChange={handleChange} />
                    </div>
                </div>

                <div className="mx-auto">
                    <LoadingButton
                        type="submit"
                        className={`${hasEmailAndPassword()? "bg-blue-500" : "bg-gray-400"} text-white py-2 px-4 rounded my-2`}
                        disabled={!hasEmailAndPassword() && !isCreating}
                        text="Create"
                        isLoading={isCreating}
                        onClick={handleCreate} />
                </div>
                {message && <div className="text-red-500">{message}</div>}

                <div className="text-sm mt-6">
                    Already have an account? <a href="/" className="text-blue-500 underline text-sm mt-6">
                        Login
                    </a>
                </div>
            </form>
        </div>
    </div>;
}

export default SignupPage;
