import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { logEvent } from '../analytics';
import lfApi from '../api/lfApi';
import LoadingButton from './LoadingButton';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function logSubscribeEvent() {
  logEvent({
      category: 'subscribe',
      action: 'subscribe',
      label: 'child_profile_add',
      props: {},
  });
}

const CheckoutButton = ({text = 'Subscribe', price_type, quantity}) => {
  const handleClick = async () => {
    logSubscribeEvent();

    const stripe = await stripePromise;

    const response = await lfApi.post('/subscription/checkout_session', {
        price_type: price_type,
        quantity: quantity,
    });

    const session = await response.data;

    // setTimeout() and sleep() are needed because logSubscribeEvent() doesn't 
    // trigger immediately
    const delay = 5000;
    setTimeout(async () => {
      const result = await stripe.redirectToCheckout({
        sessionId: session.session_id,
      });

      if (result.error) {
        console.error(result.error.message);
      }
    }, delay);

    await sleep(delay);
  };

  return (
    <LoadingButton onClick={handleClick} text={text} />
  );
};

export default CheckoutButton;
