import React, { useState } from 'react';
import { Routes, Route, useNavigate, useParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import StudyLesson from './StudyLesson';
import LoadingButton from './LoadingButton';
import lfApi from '../api/lfApi';
import { stream_get, stream_post } from '../api/lfApiFetch';
import { parseLines, processText } from '../api/stream';
import { generateUniqueId } from '../api/uniqueId';
import { extendArray } from '../api/array';
import StudyPlanTopic from './StudyPlanTopic';
import StepIndicator from './StepIndicator';

function getStudyPlan(plan, lessons) {
    return {
        subject: plan.subject,
        goal: plan.goal,
        previous_knowledge: plan.previous_knowledge,
        needs: plan.needs,
        emoji: plan.emoji,
        num_lessons: parseInt(plan.num_lessons),
        lessons: lessons,
    }
};

async function processResponse(res, setResponse) {
    await processText(res, (text) => setResponse(parseLines(text)));
};

function parseLessons(lessons) {
    return parseLines(lessons).map((lesson) => {
        const separator = lesson.indexOf(':');
        const id = generateUniqueId('lesson_');
        if (separator === -1) {
            return {
                id: id,
                title: lesson,
                explanation: '',
            }
        }
        else {
            return {
                id: id,
                title: lesson.substring(0, separator),
                explanation: lesson.substring(separator + 1).trim(),
            }
        }
    });
};

function parseTopics(lessons, topics) {
    const topics_list = extendArray(
        parseLines(topics)
            .map((topic) => {
                const separator = topic.indexOf(':');
                const id = generateUniqueId('topic_');
                if (separator === -1) {
                    return null;
                }
                else {
                    return {
                        lesson_index: parseInt(topic.substring(0, separator)) - 1,
                        id: id,
                        title: topic.substring(separator + 1).trim(),
                    }
                }
            })
            .filter((topic) => topic !== null)
            .reduce((acc, topic) => {
                acc = extendArray(acc, topic.lesson_index + 1, []);
                acc[topic.lesson_index].push({
                    id: topic.id,
                    title: topic.title,
                });
                return acc;
            }, []), 
        lessons.length, 
        []);

    return lessons.map((lesson, index) => {
        return {
            ...lesson,
            topics: topics_list[index],
        };
    });
};

async function streamStudyPlan(plan, username, planProp, llm_name, setLessons) {
    const params = { 
        ...plan, 
        num_lessons: planProp.num_lessons,
        child_username: username,
        nonse: planProp.nonse,
        llm_name,
    };
    const res = await stream_get('lesson_plan/stream', params);
    const fullText = await processText(res, (text) => setLessons(parseLessons(text)));
    return parseLessons(fullText);
};

async function streamStudyPlanTopics(plan, username, lessons, planProp, llm_name, setLessons) {
    const params = {
        child_username: username,
        subject: plan.subject,
        goal: plan.goal,
        lessons,
        nonse: planProp.nonse,
        llm_name,
    };
    const res = await stream_post('lesson_plan/topics', params);
    await processText(res, (text) => setLessons(parseTopics(lessons, text)));
};

const AddStudyPlan = ({ lessonPlan, onOk }) => {
    let { username } = useParams();

    const [plan, setPlan] = useState(lessonPlan || {
        'subject': '',
        'goal': '',
        'previous_knowledge': '',
        'needs': '',
        'num_lessons': '5',
        'emoji': '',
        'lessons': [],
    });
    const [lessons1, setLessons1] = useState(lessonPlan ? lessonPlan.lessons : []);
    const [lessons2, setLessons2] = useState([]);
    const [lessonsWithTopics1, setLessonsWithTopics1] = useState([]);
    const [lessonsWithTopics2, setLessonsWithTopics2] = useState([]);

    const minNumLessons = 3;
    const maxNumLessons = 8;

    const maxNonse = 5;
    const [planProp1, setPlanProp1] = useState({ nonse: 0, num_lessons: plan.num_lessons });
    const [planProp2, setPlanProp2] = useState({ nonse: 0, num_lessons: plan.num_lessons });

    const subjects = [
        "Math", "Science", "English", "History", "Social studies",
        "Geography", "Art", "Music",
        "Environmental studies", "Physics", "Chemistry",
        "Robotics", "Coding", "Chess", "Financial literacy", "Economics",
        "Creative writing", "Spanish", "Japanese", "Photography"
    ];

    const [goals, setGoals] = useState([]);
    const [previousKnowledges, setPreviousKnowledges] = useState([]);
    const [needs, setNeeds] = useState([]);
    const [emojis, setEmojis] = useState([]);

    const [draggingDraggableId, setDraggingDraggableId] = useState(false);

    const navigate = useNavigate();

    const navigateToRoot = () => {
        navigate('');
    };

    const navigateToGoal = async () => {
        navigate('goal');
        await fetchStudyPlanGoals();
    };

    const navigateToPreviousKnowledge = async () => {
        navigate('previous_knowledge');
        await fetchStudyPlanPreviousKnowledge();
    };

    const navigateToNeeds = async () => {
        navigate('needs');
        await fetchStudyPlanNeeds();
    };

    const navigateToEmojis = async () => {
        navigate('emojis');
        await fetchStudyPlanEmojis();
    };

    const navigateToPreview = async () => {
        navigate('preview');
        await fetchSuggestedStudyPlans(plan);
    };

    const navigateToTopics = async (lessons, planProp) => {
        setPlan({ ...plan, num_lessons: planProp.num_lessons, lessons: lessons });

        navigate('topics');
        await fetchSuggestedTopics(lessons);
    };

    const _incrementNonse = (planProp, setPlanProp, maxNonse) => {
        const n = (planProp.nonse + 1) % maxNonse;
        const newState = { ...planProp, nonse: n };
        setPlanProp(newState);
        return newState;
    };

    const incrementNonse1 = () => {
        return _incrementNonse(planProp1, setPlanProp1, maxNonse);
    };

    const incrementNonse2 = () => {
        return _incrementNonse(planProp2, setPlanProp2, maxNonse);
    };

    const _incrementNumLessons = (planProp, setPlanProp, incr) => {
        const currentNumLessons = parseInt(planProp.num_lessons);
        const newNumLessons = currentNumLessons + incr;
        if (newNumLessons < minNumLessons || newNumLessons > maxNumLessons) {
            return;
        }
        const newState = { ...planProp, num_lessons: newNumLessons.toString() };
        setPlanProp(newState);
        return newState;
    };

    const incrementNumLessons1 = (incr) => {
        return _incrementNumLessons(planProp1, setPlanProp1, incr);
    };

    const incrementNumLessons2 = (incr) => {
        return _incrementNumLessons(planProp2, setPlanProp2, incr);
    };

    const renderStep = (stepNum) => {
        return <div>
            <StepIndicator 
                currentStep={stepNum} numSteps={7} 
                onClicks={[
                    navigateToRoot, navigateToGoal, navigateToPreviousKnowledge, navigateToNeeds,
                    navigateToEmojis, navigateToPreview
                ]}/>
        </div>
    };
    
    const fetchStudyPlanGoals = async () => {
        setGoals([]);

        try {
            const res = await stream_get('lesson_plan/goals', {
                child_username: username,
                subject: plan.subject,
            });
            await processResponse(res, setGoals)
        }
        catch (error) {
            console.error('Error fetching study plan goals: ', error);
            if (!error.response || error.response.status === 401) {
                setGoals([]);
            }
        }
    };

    const fetchStudyPlanPreviousKnowledge = async () => {
        setPreviousKnowledges([]);

        try {
            const res = await stream_get('lesson_plan/previous_knowledge', {
                child_username: username,
                subject: plan.subject,
                goal: plan.goal,
            });
            await processResponse(res, setPreviousKnowledges)
        }
        catch (error) {
            console.error('Error fetching study plan previous knowledge: ', error);
            if (!error.response || error.response.status === 401) {
                setPreviousKnowledges([]);
            }
        }
    };

    const fetchStudyPlanNeeds = async () => {
        setNeeds([]);

        try {
            const res = await stream_get('lesson_plan/needs', {
                child_username: username,
                subject: plan.subject,
                goal: plan.goal,
                previous_knowledge: plan.previous_knowledge,
            });
            await processResponse(res, setNeeds)
        }
        catch (error) {
            console.error('Error fetching study plan needs: ', error);
            if (!error.response || error.response.status === 401) {
                setNeeds([]);
            }
        }
    };

    const fetchStudyPlanEmojis = async () => {
        setEmojis([]);

        try {
            const res = await lfApi.get('lesson_plan/emojis', {
                params: {
                    child_username: username,
                    subject: plan.subject,
                    goal: plan.goal,    
                }
            });
            setEmojis(res.data.emojis);
        }
        catch (error) {
            console.error('Error fetching study plan emojis: ', error);
        }
    };

    const fetchSuggestedStudyPlan1 = async (plan, planProp) => {
        await streamStudyPlan(plan, username, planProp, '', setLessons1);
    };

    const fetchSuggestedStudyPlan2 = async (plan, planProp) => {
        await streamStudyPlan(plan, username, planProp, 'gemini', setLessons2);
    };

    const fetchSuggestedStudyPlans = async (plan) => {
        await Promise.all([
            fetchSuggestedStudyPlan1(plan, planProp1),
            fetchSuggestedStudyPlan2(plan, planProp2),
        ]);
    };

    const fetchSuggestedTopic1 = async (lessons, planProp) => {
        await streamStudyPlanTopics(plan, username, lessons, planProp, '', setLessonsWithTopics1);
    };

    const fetchSuggestedTopic2 = async (lessons, planProp) => {
        await streamStudyPlanTopics(plan, username, lessons, planProp, 'gemini', setLessonsWithTopics2);
    };

    const fetchSuggestedTopics = async (lessons) => {
        await Promise.all([
            fetchSuggestedTopic1(lessons, planProp1.nonse),
            fetchSuggestedTopic2(lessons, planProp2.nonse),
        ]);
    };

    const onDragLesson = (result) => {
        const { source, destination } = result;

        setDraggingDraggableId('');

        if (!destination || source.droppableId === destination.droppableId) {
            const { lessons, setLessons } = source.droppableId === 'lesson1'
                ? { lessons: lessons1, setLessons: setLessons1 }
                : { lessons: lessons2, setLessons: setLessons2 };

            const [removed] = lessons.splice(source.index, 1);
            if (destination)
                lessons.splice(destination.index, 0, removed);
            setLessons([...lessons]);
        } else {
            const { sourceLessons, setSourceLessons } = source.droppableId === 'lesson1'
                ? { sourceLessons: lessons1, setSourceLessons: setLessons1 }
                : { sourceLessons: lessons2, setSourceLessons: setLessons2 };
            const { destinationLessons, setDestinationLessons } = destination.droppableId === 'lesson1'
                ? { destinationLessons: lessons1, setDestinationLessons: setLessons1 }
                : { destinationLessons: lessons2, setDestinationLessons: setLessons2 };

            const [removed] = sourceLessons.splice(source.index, 1);
            destinationLessons.splice(destination.index, 0, removed);

            setSourceLessons([...sourceLessons]);
            setDestinationLessons([...destinationLessons]);
        }
    };

    const onDragTopic = (result) => {
        const { source, destination } = result;

        setDraggingDraggableId('');

        if (!destination) {
            // DroppableIds are in the format '<topics>:<lessonIndex>'
            const sourceDroppableId = source.droppableId.split(':')[0];
            const sourceLessonIndex = parseInt(source.droppableId.split(':')[1], 10);

            // Index is the topic index within the lesson
            const sourceTopicIndex = source.index;

            const { lessons, setLessons } = sourceDroppableId === 'topics1'
                ? { lessons: lessonsWithTopics1, setLessons: setLessonsWithTopics1 }
                : { lessons: lessonsWithTopics2, setLessons: setLessonsWithTopics2 };

            // Remove topic from the source lesson
            const newSourceLessons = [...lessons];
            const sourceLesson = newSourceLessons[sourceLessonIndex];
            sourceLesson.topics.splice(sourceTopicIndex, 1);

            setLessons([...newSourceLessons]);
        } else {
            const getLessonsList = (droppableId) => (droppableId === 'topics1' ? lessonsWithTopics1 : lessonsWithTopics2);
            const setLessonsList = (droppableId, newLessons) => (droppableId === 'topics1' ? setLessonsWithTopics1(newLessons) : setLessonsWithTopics2(newLessons));
        
            const sourceDroppableId = source.droppableId.split(':')[0];
            const destinationDroppableId = destination.droppableId.split(':')[0];

            const sourceLessons = getLessonsList(sourceDroppableId);
            const destinationLessons = getLessonsList(destinationDroppableId);
        
            // DroppableIds are in the format '<topics>:<lessonIndex>'
            const sourceLessonIndex = parseInt(source.droppableId.split(':')[1], 10);
            const destinationLessonIndex = parseInt(destination.droppableId.split(':')[1], 10);

            // Index is the topic index within the lesson
            const sourceTopicIndex = source.index;
            const destinationTopicIndex = destination.index;

            // Remove topic from the source lesson
            const newSourceLessons = [...sourceLessons];
            const sourceLesson = newSourceLessons[sourceLessonIndex];
            const [movedTopic] = sourceLesson.topics.splice(sourceTopicIndex, 1);
        
            if (sourceDroppableId === destinationDroppableId && sourceLessonIndex === destinationLessonIndex) {
                // Moving within the same list
                sourceLesson.topics.splice(destinationTopicIndex, 0, movedTopic);
                setLessonsList(sourceDroppableId, newSourceLessons);
            } else {
                // Moving between lists
                const newDestinationLessons = [...destinationLessons];
                const destinationLesson = newDestinationLessons[destinationLessonIndex];
                destinationLesson.topics.splice(destinationTopicIndex, 0, movedTopic);
            
                setLessonsList(sourceDroppableId, newSourceLessons);
                setLessonsList(destinationDroppableId, newDestinationLessons);
            }
        }
    };

    const onDragUpdate = (update) => {
        setDraggingDraggableId(!update.destination ? update.draggableId : '');
    };
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setPlan(prevState => ({ ...prevState, [name]: value }));
    };

    return <div className="text-left mx-auto max-w-screen-lg">
        <h1 className="text-xl">Add study plan</h1>

        <div className="mb-8">
            <div>
                A study plan is a personalized learning plan designed to guide your child through their learning goals.
            </div>
        </div>

        <Routes>
            <Route path="" element={<div>
                { renderStep(1) }
                <div className="text-lg">
                    Enter the subject:
                </div>
                <input
                    type="text"
                    className="mt-2 w-full md:w-2/3 border border-gray-300 p-1"
                    name="subject"
                    value={plan.subject}
                    onChange={handleChange} />

                <div className="my-6 md:w-2/3">
                    {
                        subjects.map((subject, index) => (
                            <button key={index}
                                className={`${plan.subject === subject ? "text-blue-500 border-blue-500" : "text-gray-500 border-gray-500"} bg-white text-sm border rounded-lg p-1 mr-1 mb-2`}
                                onClick={() => setPlan({ ...plan, subject: subject })}>
                                {subject}
                            </button>
                        ))
                    }
                </div>

                <button
                    className={`${plan.subject.length === 0 ? "bg-gray-400" : "bg-blue-500"} text-white py-2 px-4 rounded`}
                    disabled={plan.subject.length === 0}
                    onClick={navigateToGoal}>
                    Next
                </button>
            </div>} />
            <Route path="goal" element={<div>
                { renderStep(2) }
                <div className="text-lg">
                    Enter learning goals:
                </div>
                <textarea
                    className="mt-2 w-full border border-gray-300 p-1"
                    name="goal"
                    value={plan.goal}
                    onChange={handleChange} />

                <div className="my-6">
                    {
                        goals.map((goal, index) => (
                            <button key={index}
                                className={`${plan.goal === goal ? "text-blue-500 border-blue-500" : "text-gray-500 border-gray-500"} bg-white text-sm text-left border rounded-lg p-1 mr-1 mb-2`}
                                onClick={() => setPlan({ ...plan, goal: goal })}>
                                {goal}
                            </button>
                        ))
                    }
                </div>

                <button
                    className={`${plan.goal.length === 0 ? "bg-gray-400" : "bg-blue-500"} text-white py-2 px-4 rounded`}
                    disabled={plan.goal.length === 0}
                    onClick={navigateToPreviousKnowledge}>
                    Next
                </button>
            </div>} />
            <Route path="previous_knowledge" element={<div>
                { renderStep(3) }
                <div className="text-lg">
                    Enter previous knowledge:
                </div>
                <textarea
                    className="mt-2 w-full border border-gray-300 p-1"
                    name="previous_knowledge"
                    value={plan.previous_knowledge}
                    onChange={handleChange} />

                <div className="my-6">
                    {
                        previousKnowledges.map((previousKnowledge, index) => (
                            <button key={index}
                                className={`${plan.previous_knowledge === previousKnowledge ? "text-blue-500 border-blue-500" : "text-gray-500 border-gray-500"} bg-white text-sm text-left border rounded-lg p-1 mr-1 mb-2`}
                                onClick={() => setPlan({ ...plan, previous_knowledge: previousKnowledge })}>
                                {previousKnowledge}
                            </button>
                        ))
                    }
                </div>

                <button
                    className={`${plan.previous_knowledge.length === 0 ? "bg-gray-400" : "bg-blue-500"} text-white py-2 px-4 rounded`}
                    disabled={plan.previous_knowledge.length === 0}
                    onClick={navigateToNeeds}>
                    Next
                </button>
            </div>} />
            <Route path="needs" element={<div>
                { renderStep(4) }
                <div className="text-lg">
                    Enter educational needs (optional):
                </div>
                <textarea
                    className="mt-2 w-full border border-gray-300 p-1"
                    name="needs"
                    placeholder="eg, Requires extra help with grammar and sentence structure, etc"
                    value={plan.needs}
                    onChange={handleChange} />

                <div className="my-6">
                    {
                        needs.map((needs, index) => (
                            <button key={index}
                                className={`${plan.needs === needs ? "text-blue-500 border-blue-500" : "text-gray-500 border-gray-500"} bg-white text-sm text-left border rounded-lg p-1 mr-1 mb-2`}
                                onClick={() => setPlan({ ...plan, needs: needs })}>
                                {needs}
                            </button>
                        ))
                    }
                </div>

                <button className="bg-blue-500 text-white py-2 px-4 rounded"
                    onClick={navigateToEmojis}>
                    Next
                </button>
            </div>} />
            <Route path="emojis" element={<div>
                { renderStep(5) }
                <div className="text-lg">
                    Enter study plan emoji:
                </div>
                <input 
                    type="text"
                    className="mt-2 w-full border border-gray-300 p-1"
                    name="emoji"
                    maxLength={2}
                    value={plan.emoji}
                    onChange={handleChange} />

                <div className="my-6">
                    {
                        emojis.map((emoji, index) => (
                            <button key={index}
                                className={`${plan.emoji === emoji ? "text-blue-500 border-blue-500" : "text-gray-500 border-gray-500"} bg-white text-sm border rounded-lg p-1 mr-2 mb-2`}
                                onClick={() => setPlan({ ...plan, emoji: emoji })}>
                                {emoji}
                            </button>
                        ))
                    }
                </div>

                <button 
                    className={`${plan.emoji.length === 0 ? "bg-gray-400" : "bg-blue-500"} text-white py-2 px-4 rounded`}
                    disabled={plan.emoji.length === 0}
                    onClick={navigateToPreview}>
                    Preview study plan
                </button>
            </div>} />
            <Route path="preview" element={<div>
                { renderStep(6) }
                <div className="text-lg">
                    Review study plan
                </div>
                <div>
                    Here are the suggested study plans. You can accept one of the suggestions or reorder lessons via drag and drop.
                    You can also go back to edit the study plan.
                </div>

                <div className="flex flex-col md:flex-row gap-4 text-left mt-4">
                    <DragDropContext onDragEnd={onDragLesson} onDragUpdate={onDragUpdate}>
                        <div className="flex-1 relative md:pb-4">
                            {
                                lessons2.length > 0 && <>
                                    <hr className="md:hidden" />
                                    <div className="flex items-center my-2">
                                        <div className="mr-1">Suggestion 1:</div>
                                        <LoadingButton imgSrc="/icon-refresh.png" text="Generate new suggestion" 
                                            onClick={async () => await fetchSuggestedStudyPlan1(plan, incrementNonse1())} />
                                        <LoadingButton imgSrc="/icon-list-plus2.png" text="Make longer" 
                                            onClick={async () => await fetchSuggestedStudyPlan1(plan, incrementNumLessons1(1))} />
                                        <LoadingButton imgSrc="/icon-list-minus2.png" text="Make shorter" 
                                            onClick={async () => await fetchSuggestedStudyPlan1(plan, incrementNumLessons1(-1))} />
                                    </div>
                                </>
                            }
                            <Droppable droppableId="lesson1">
                                {(provided) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {
                                            lessons1.map((lesson, index) => (
                                                <Draggable key={`lesson1:${index}`} draggableId={`lesson1:${index}`} index={index}>
                                                    {(provided) => (
                                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                            className={`${`lesson1:${index}` === draggingDraggableId ? 'opacity-50' : 'opacity-100'}`}>
                                                            <StudyLesson lesson={lesson} index={index} isLastLesson={index === lessons1.length - 1} />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))
                                        }
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                            {
                                lessons2.length > 0 && <div className="md:hidden">
                                    <button className="bg-blue-500 text-white py-2 px-4 rounded"
                                        onClick={() => navigateToTopics(lessons1, planProp1)}>
                                        Accept suggestion 1
                                    </button>
                                </div>
                            }
                        </div>

                        {
                            lessons2.length > 0 && <div className="flex-1 relative pb-4">
                                    <hr className="md:hidden" />
                                    <div className="flex items-center my-2">
                                        <div className="mr-1">Suggestion 2:</div>
                                        <LoadingButton imgSrc="/icon-refresh.png" text="Generate new suggestion" 
                                            onClick={async () => await fetchSuggestedStudyPlan2(plan, incrementNonse2())} />
                                        <LoadingButton imgSrc="/icon-list-plus2.png" text="Make longer" 
                                            onClick={async () => await fetchSuggestedStudyPlan2(plan, incrementNumLessons2(1))} />
                                        <LoadingButton imgSrc="/icon-list-minus2.png" text="Make shorter" 
                                            onClick={async () => await fetchSuggestedStudyPlan2(plan, incrementNumLessons2(-1))} />
                                    </div>
                                    <Droppable droppableId="lesson2">
                                    {(provided) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            {
                                                lessons2.map((lesson, index) => (
                                                    <Draggable key={`lesson2:${index}`} draggableId={`lesson2:${index}`} index={index}>
                                                        {(provided) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                                className={`${`lesson2:${index}` === draggingDraggableId ? 'opacity-50' : 'opacity-100'}`}>
                                                                <StudyLesson lesson={lesson} index={index} isLastLesson={index === lessons2.length - 1} />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))
                                            }
                                            {provided.placeholder}
                                        </div>
                                    )}
                                    </Droppable>
                                    <div className="md:hidden">
                                        <button className="bg-blue-500 text-white py-2 px-4 rounded"
                                            onClick={() => navigateToTopics(lessons2, planProp2)}>
                                            Accept suggestion 2
                                        </button>
                                    </div>
                            </div>
                        }
                    </DragDropContext>
                </div>

                {
                    lessons2.length > 0 && <div className="hidden md:flex justify-center gap-2 mt-4">
                        <button className="bg-blue-500 text-white py-2 px-4 rounded ml-12"
                            onClick={() => navigateToTopics(lessons1, planProp1)}>
                            Accept suggestion 1
                        </button>
                        <button className="bg-blue-500 text-white py-2 px-4 rounded"
                            onClick={() => navigateToTopics(lessons2, planProp2)}>
                            Accept suggestion 2
                        </button>
                    </div>
                }
            </div>} />

            <Route path="topics" element={<div>
                { renderStep(7) }
                <div className="text-lg">
                    Review topics
                </div>
                <div>
                    Here are the suggested study plans with topics. You can accept one of the suggestions or reorder topics via drag and drop.
                    You can also go back to edit the study plan.
                </div>

                <div className="flex flex-col md:flex-row gap-4 text-left mt-4">
                    <DragDropContext onDragEnd={onDragTopic} onDragUpdate={onDragUpdate}>
                        <div className="flex-1 relative md:pb-4">
                            <hr className="md:hidden" />
                            <div className="flex items-center my-2">
                                <div>Suggestion 1:</div>
                                <LoadingButton imgSrc="/icon-refresh.png" text="Generate new suggestion" 
                                    onClick={async () => await fetchSuggestedTopic1(plan.lessons, incrementNonse1())} />
                            </div>
                            {
                                lessonsWithTopics1.map((lesson, lessonIndex) => (
                                    <Droppable key={`topics1:${lessonIndex}`} droppableId={`topics1:${lessonIndex}`}>
                                        {(provided) => (
                                            <div ref={provided.innerRef} {...provided.droppableProps} className="text-gray-500">
                                                <StudyLesson lesson={lesson} index={lessonIndex} isLastLesson={lessonIndex === lessons1.length - 1}>
                                                    {
                                                        lesson.topics && lesson.topics.map((topic, topicIndex) => (
                                                            <div key={`lesson1:${topic.id}`} className="text-black">
                                                                <Draggable 
                                                                        draggableId={`topics1:${lessonIndex}:${topicIndex}`} 
                                                                        key={`topics1:${lessonIndex}:${topicIndex}`} 
                                                                        index={topicIndex}>
                                                                    {(provided) => (
                                                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                                            className={`${`topics1:${lessonIndex}:${topicIndex}` === draggingDraggableId ? 'opacity-50' : 'opacity-100'}`}>
                                                                            <StudyPlanTopic topic={topic} index={topicIndex} />
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            </div>
                                                        ))
                                                    }
                                                    {provided.placeholder}
                                                </StudyLesson>
                                            </div>
                                        )}
                                    </Droppable>
                                ))
                            }
                            {
                                lessons2.length > 0 && <div className="md:hidden">
                                        <button className="bg-blue-500 text-white py-2 px-4 rounded"
                                            onClick={() => onOk(getStudyPlan(plan, lessonsWithTopics1))}>
                                        Accept suggestion 1
                                    </button>
                                </div>
                            }
                        </div>

                        <div className="flex-1 relative pb-4">
                            <hr className="md:hidden" />
                            <div className="flex items-center my-2">
                                <div>Suggestion 2:</div>
                                <LoadingButton imgSrc="/icon-refresh.png" text="Generate new suggestion" 
                                    onClick={async () => await fetchSuggestedTopic2(plan.lessons, incrementNonse2())} />
                            </div>
                            {
                                lessonsWithTopics2.map((lesson, lessonIndex) => (
                                    <Droppable key={`topics2:${lessonIndex}`} droppableId={`topics2:${lessonIndex}`}>
                                        {(provided) => (
                                            <div ref={provided.innerRef} {...provided.droppableProps} className="text-gray-500">
                                                <StudyLesson lesson={lesson} index={lessonIndex} isLastLesson={lessonIndex === lessons1.length - 1}>
                                                    {
                                                        lesson.topics && lesson.topics.map((topic, topicIndex) => (
                                                            <div key={`lesson2:${topic.id}`} className="text-black">
                                                                <Draggable draggableId={`topics2:${lessonIndex}:${topicIndex}`} 
                                                                        key={`${lessonIndex}:${topicIndex}`} 
                                                                        index={topicIndex}>
                                                                    {(provided) => (
                                                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                                            className={`${`topics2:${lessonIndex}:${topicIndex}` === draggingDraggableId ? 'opacity-50' : 'opacity-100'}`}>
                                                                            <StudyPlanTopic topic={topic} index={topicIndex} />
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            </div>
                                                        ))
                                                    }
                                                    {provided.placeholder}
                                                </StudyLesson>
                                            </div>
                                        )}
                                    </Droppable>
                                ))
                            }
                            <div className="md:hidden">
                                <button className="bg-blue-500 text-white py-2 px-4 rounded"
                                    onClick={() => onOk(getStudyPlan(plan, lessonsWithTopics2))}>
                                    Accept suggestion 2
                                </button>
                            </div>
                        </div>
                    </DragDropContext>
                </div>

                {
                    lessons2.length > 0 && <div className="hidden md:flex justify-center gap-2 mt-4">
                        <button className="bg-blue-500 text-white py-2 px-4 rounded ml-12"
                            onClick={() => onOk(getStudyPlan(plan, lessonsWithTopics1))}>
                            Accept suggestion 1
                        </button>
                        <button className="bg-blue-500 text-white py-2 px-4 rounded"
                            onClick={() => onOk(getStudyPlan(plan, lessonsWithTopics2))}>
                            Accept suggestion 2
                        </button>
                    </div>
                }
            </div>} />
        </Routes>
    </div>
};

export default AddStudyPlan;
